import React, { useEffect, useState } from 'react'

import cn from 'classnames'
import * as s from './LoadingScreen.module.scss'
import Pacman from './Pacman'

const LoadingScreen = () => {
  const [loaded, setloaded] = useState(false)

  useEffect(() => {
    if (sessionStorage.getItem('isPreloaded')) {
      document.body.style.overflow = 'auto'
      setloaded(true)
    }
  }, [])

  return (
    <div
      id="loading"
      className={cn(s.loadingscreen, { hide: loaded })}
      data-lenis-prevent
    >
      <Pacman />

      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width={159}
        height={36}
        viewBox="0 0 159 36"
        className={s.loadingscreen_logo}
      >
        <path
          fill="#000"
          d="M23.2 2h10.1v33.2h-5.6V5.8l-7 29.4h-8L5.5 5.8h.1v29.4H0V2h10l6.8 30h-.4l6.8-30Zm42.4 33.2V21.7c0-1-.2-2-.5-2.9a4 4 0 0 0-1.6-2c-.7-.5-1.7-.8-3-.8a5.6 5.6 0 0 0-5.2 3.1 7 7 0 0 0-.7 3.3l-2-1.2a10 10 0 0 1 10.1-10.3c1.9 0 3.5.3 4.8 1 1.3.8 2.3 2 3 3.3.7 1.4 1 3 1 4.8v15.1h-6Zm-17 0v-24h6v24h-6Zm-11 0v-24h5.9v24h-5.9Zm3-28.4a4 4 0 0 1-2.5-.7A2.7 2.7 0 0 1 37 3.9c0-1 .4-1.6 1-2.1.8-.5 1.6-.8 2.6-.8s1.7.3 2.5.8c.7.5 1 1.2 1 2.1 0 1-.3 1.7-1 2.2-.8.5-1.6.8-2.5.8Zm35 28.4v-24h5.9v24h-5.9Zm3-28.3c-1 0-1.8-.3-2.5-.8S75 4.8 75 3.9c0-.9.4-1.6 1-2.1.8-.5 1.6-.8 2.6-.8s1.7.3 2.5.8c.7.5 1 1.2 1 2.1 0 1-.3 1.7-1 2.2-.8.5-1.6.8-2.5.8Zm22.2 28.3V19.6a5 5 0 0 0-.6-1.7 3 3 0 0 0-1.2-1.3 4 4 0 0 0-2-.6 4.4 4.4 0 0 0-3.9 2l-.8 2.1-.3 2.4-2-1.3c0-2 .4-3.7 1.1-5.3a8.5 8.5 0 0 1 8-5c2 0 3.4.4 4.6 1.2a7 7 0 0 1 2.4 3.2c.5 1.4.7 3 .7 4.6v15.2h-5.9Zm-14.8 0v-24h6v24h-6Zm29.6 0V21.4l-.1-1.9c0-.6-.2-1.2-.5-1.7a3 3 0 0 0-1.2-1.3c-.5-.3-1.3-.5-2.2-.5a4 4 0 0 0-2.3.6 4 4 0 0 0-1.5 1.4c-.4.6-.6 1.3-.8 2a9 9 0 0 0-.3 2.3l-2-1.3c0-2 .4-3.6 1.1-5.2a8.5 8.5 0 0 1 8-5 8 8 0 0 1 4.5 1.2 6 6 0 0 1 2.4 3.2c.6 1.3.8 2.8.8 4.5v15.5h-5.9Zm18.4.5c-1.8 0-3.3-.3-4.7-.9a7.3 7.3 0 0 1-3.2-2.4c-.7-1-1.1-2.4-1.1-3.9 0-1.6.4-3 1.3-4 1-1 2.1-1.9 3.6-2.4 1.6-.6 3.2-1 5-1.2a56 56 0 0 0 5.8-.7c1.1-.3 1.6-.7 1.6-1.3 0-1-.4-1.9-1.4-2.4-1-.6-2.3-.9-4-.9-1.8 0-3.2.3-4.2 1-1 .6-1.4 1.6-1.4 3h-5.9c0-1.9.5-3.5 1.5-4.7 1-1.4 2.4-2.3 4.2-3 1.7-.7 3.7-1 5.9-1 2 0 4 .3 5.6.9 1.7.6 3.1 1.5 4.1 2.8a7 7 0 0 1 1.6 4.6v11a31.3 31.3 0 0 0 .3 3 5 5 0 0 0 1 2h-5.8l-.5-.6a7.3 7.3 0 0 1-.8-2.6l-.1-1.7 1.1.8c-.4 1-1.1 1.7-2 2.4-1 .7-2 1.2-3.3 1.6-1.3.4-2.7.6-4.2.6Zm1.5-4.7c1.2 0 2.3-.2 3.4-.6 1-.4 1.9-1 2.5-2 .7-.8 1-2 1-3.4v-3.2l1.4 1.7-3.4.9a61 61 0 0 1-4.1.5 11 11 0 0 0-4 1c-1 .4-1.4 1.1-1.4 2.1s.4 1.8 1.2 2.3c.7.5 1.8.7 3.4.7Zm23.3-31v35.2H153V0h5.8Z"
        />
      </svg>
    </div>
  )
}

export default LoadingScreen
