/* eslint-disable react/no-danger */
import React from 'react'
import LoadingScreen from '~components/LoadingScreen'
import PACMAN_ANIM_DURATION_MS from '~constants/pacman'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const PageWrapper = ({ element }) => {
  return (
    <>
      {element}
      <LoadingScreen />

      <script
        dangerouslySetInnerHTML={{
          __html: `
          let isPreloaded = sessionStorage.getItem("isPreloaded");
          document.querySelector("#pacman").style.setProperty("visibility", isPreloaded ? "hidden" : "visible" ); 
          setTimeout(()=>{
            if(!isPreloaded){
              document.body.style.overflow = "auto"
              document.querySelector("#loading").classList.add("hide"); 
              sessionStorage.setItem("isPreloaded", true)
            }
          }, ${PACMAN_ANIM_DURATION_MS});
          `,
        }}
      />
    </>
  )
}

export default PageWrapper
