import React from 'react'

import * as s from './Pacman.module.scss'

const Pacman = () => (
  <div id="pacman" className={s.pacman}>
    <svg
      className={s.pacman_inner}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 65"
    >
      <path d="M9.4 55.4a32 32 0 0 1 0-45.3L24 18l8 14.7L22 45 9.4 55.4Z" />
      <path d="M54.6 55.4a32 32 0 0 1-45.2 0L32 32.7l22.6 22.7ZM9.4 10.1a32 32 0 0 1 45.2 0L32 32.7 9.4 10.1Z" />

      <path className={s.pacman_top} d="M64 32.7a32 32 0 0 0-32-32v32h32Z" />
      <g className={s.pacman_bottomWrapper}>
        <path
          className={s.pacman_bottom}
          d="M64 32.7a32 32 0 0 0-32-32v32h32Z"
        />
      </g>
    </svg>
  </div>
)

export default Pacman
